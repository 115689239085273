<template>
  <div class="form-slide">
    <img
      class="bg-pic"
      :src="pageData.bgPicURL"
      @load="handleBgPicLoad"
    />
    <div
      class="form-item-box"
      :style="{ width: `${picSize.w}px`, height: `${picSize.h}px` }"
    >
      <div
        v-for="item in pageData.formItems"
        :key="item.key"
        class="form-item"
        :style="{ width: `${item.w}px`, height: `${item.h}px`, transform: `translate(${item.x}px, ${item.y}px)` }"
      >
        <component
          :is="formItemComps[item.type]"
          :itemData="item"
          isPreview
          @click="handleCompClick(item)"
        ></component>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';

  import FormItemInput from '@/views/knowledge/index/knowledge-task/components/FormItemInput.vue';
  import FormItemTextarea from '@/views/knowledge/index/knowledge-task/components/FormItemTextarea.vue';
  import FormItemSelect from '@/views/knowledge/index/knowledge-task/components/FormItemSelect.vue';
  import FormItemRadioGroup from '@/views/knowledge/index/knowledge-task/components/FormItemRadioGroup.vue';
  import FormItemCheckboxGroup from '@/views/knowledge/index/knowledge-task/components/FormItemCheckboxGroup.vue';
  import FormItemSubmit from '@/views/knowledge/index/knowledge-task/components/FormItemSubmit.vue';
  import FormItemComment from '@/views/knowledge/index/knowledge-task/components/FormItemComment.vue';

  import { formItemTypeVal } from '@/views/knowledge/index/knowledge-task/generate-data.js';

  const emit = defineEmits(['submit-btn-click']);
  defineProps({
    useFor: {
      required: true,
      type: String
    },
    pageIndex: {
      required: true,
      type: Number
    },
    pageData: {
      required: true,
      type: Object
    },
    currentPageIndex: {
      required: true,
      type: Number
    }
  });

  const picSize = reactive({
    w: 1920,
    h: 1080
  });
  function handleBgPicLoad({ target }) {
    Object.assign(picSize, {
      w: target.width,
      h: target.height
    });
  }

  const formItemComps = {};
  formItemComps[formItemTypeVal.INPUT] = FormItemInput;
  formItemComps[formItemTypeVal.TEXTAREA] = FormItemTextarea;
  formItemComps[formItemTypeVal.SELECT] = FormItemSelect;
  formItemComps[formItemTypeVal.RADIO_GROUP] = FormItemRadioGroup;
  formItemComps[formItemTypeVal.CHECKBOX_GROUP] = FormItemCheckboxGroup;
  formItemComps[formItemTypeVal.SUBMIT] = FormItemSubmit;
  formItemComps[formItemTypeVal.COMMENT] = FormItemComment;

  function handleCompClick({ type, behaviour }) {
    switch (type) {
      case formItemTypeVal.SUBMIT:
        emit('submit-btn-click', behaviour);
        break;
    }
  }
</script>

<style lang="less" scoped>
  .form-slide {
    position: relative;
    // text-align: center;
  }

  .bg-pic {
    display: inline-block;
    vertical-align: top;
  }

  .form-item-box {
    position: absolute;
    top: 0;
    // left: 50%;
    left: 0;
    text-align: left;
  }

  .form-item {
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    border: 1px dashed transparent;
  }
</style>