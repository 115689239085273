<template>
  <div class="talk-win-box" ref="talkWinBoxRef">
    <NModal
      v-if="isBoxOnMounted"
      v-model:show="modalShow"
      preset="dialog"
      :show-icon="false"
      :style="{
        width: `${1920 * 0.75 * conversationScale}px`,
        transformOrigin: 'center center',
        transform: `scale(${1 / conversationScale})`
      }"
      :closable="false"
      :close-on-esc="false"
      :mask-closable="false"
      :to="talkWinBoxRef"
    >
      <div class="uu-editor-view" style="margin: -20px -18px;" v-html="talkTxt"></div>
    </NModal>
  </div>
</template>

<script setup>
  import { ref, onMounted, nextTick, watch } from 'vue';
  import { NModal } from 'naive-ui';

  const props = defineProps({
    people: {
      type: Object,
      default: () => {}
    },
    talkTxt: {
      type: String,
      default: ''
    },
    conversationScale: {
      type: Number,
      default: 1
    }
  });

  const talkWinBoxRef = ref(null);
  const isBoxOnMounted = ref(false);
  const modalShow = ref(false);
  onMounted(() => {
    isBoxOnMounted.value = true;
    nextTick(() => {
      modalShow.value = true;
    });
  });
  watch(
    () => props.people,
    () => {
      modalShow.value = true;
    }
  );

  defineExpose({
    close() {
      modalShow.value = false;
    }
  });
</script>

<style lang="less" scoped>
  .talk-win-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    :deep(.n-modal-container) {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      
      &,
      .n-modal-mask,
      .n-modal-body-wrapper {
        position: absolute;
      }
    }

    /*
    :deep(.n-dialog__title) {
      font-size: 23px;
    }
    :deep(.n-dialog__content) {
      font-size: 18px;
    }
    */
  }

  .uu-editor-view {
    font-size: 22px;
  }
</style>